.navbar {
  width: 100%;
  height: 66px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  border-bottom: 1px solid rgb(236, 239, 241);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;

  .inner {
    height: 100%;
    width: 100%;
    max-width: 1168px;
    margin: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 112px;
      height: 20px;
    }

    .menu {
      display: flex;
      flex-direction: row;
      height: 100%;

      a {
        text-decoration: none;
        color: black;
      }

      div {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        padding: 0 20px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          border-bottom: 5px solid rgb(0, 82, 255);
        }
      }

      @media screen and (max-width: 899px) {
        display: none;
      }
    }

    .buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      @media screen and (max-width: 889px) {
        gap: 20px;
      }

      .hideOnMobile {
        @media screen and (max-width: 889px) {
          display: none;
        }
      }

      .avatarSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0;
        position: relative;
        cursor: pointer;

        @media screen and (max-width: 889px) {
          display: none;
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;

          &.pointingDown {
            width: 14px;
            height: 14px;
          }
        }

        .dropdownMenu {
          position: absolute;
          bottom: -10px;
          right: 0;
          width: 150px;
          background-color: white;
          transform: translateY(100%);
          border: 1px solid var(--border-color);
          border-radius: 5px;

          div {
            cursor: pointer;
            padding: 10px;

            &:hover {
              background-color: #ccc;
            }
          }
        }
      }

      .mobileMenuButton {
        display: none;
        position: relative;
        width: 18px;
        height: 17px;
        cursor: pointer;

        @media screen and (max-width: 889px) {
          display: block;
        }

        .openButton {
          position: absolute;
          top: 8px;
          width: 100%;
          height: 2px;
          background-color: rgb(5, 15, 25);
          border-radius: 4px;
          transform: initial;
          transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;

          &:before {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: rgb(5, 15, 25);
            border-radius: 4px;
            content: "";
            transform: translateY(-8px);
            transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
          }

          &:after {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: rgb(5, 15, 25);
            border-radius: 4px;
            content: "";
            transform: translateY(8px) rotate(0deg);
            transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
          }
        }

        .closeButton {
          position: absolute;
          top: 8px;
          width: 100%;
          height: 2px;
          background-color: transparent;
          border-radius: 4px;
          transform: rotate(45deg);
          transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;

          &:before {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: rgb(5, 15, 25);
            border-radius: 4px;
            content: "";
            transform: translateY(0px);
            transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
          }

          &:after {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: rgb(5, 15, 25);
            border-radius: 4px;
            content: "";
            transform: translateY(0px) rotate(-90deg);
            transition: transform 300ms ease 0s, background-color 0s cubic-bezier(0.6, 0, 0.15, 1) 0s;
          }
        }
      }
    }
  }
}

.mobileMenu {
  visibility: hidden;
  position: fixed;
  top: 67px;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;

  @media screen and (max-width: 889px) {
    visibility: unset;
  }

  .menuList {
    width: 100%;

    a {
      text-decoration: none;
      color: black;
      width: 100%;
    }

    div {
      font-weight: 500;
      font-size: 16px;
      padding: 20px;
    }
  }

  .mobileButtons {
    margin-top: 20px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      @media screen and (max-width: 320px) {
        width: 70px;
        height: 70px;
        margin-bottom: 10px;
      }
    }

    p {
      padding: 0;
      margin-bottom: 10px;
      font-size: 16px;

      @media screen and (max-width: 320px) {
        display: none;
      }
    }
  }
}
