.loginPage {
  width: 100%;
  max-width: 467px;

  .loginBox {
    padding: 20px;
    margin: 10px;
    border: 1px solid var(--border-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 559px) {
      gap: 0;
    }

    h1 {
      padding: 0;
      margin: 0 0 20px 0;

      @media (max-width: 559px) {
        font-size: 24px;
      }
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .inputElement {
        display: flex;
        flex-direction: column;

        input {
          margin-top: 10px;
          font-size: 16px;
          padding: 20px 10px;
          border: 1px solid var(--border-color);
          border-radius: 10px;

          @media screen and (max-width: 559px) {
            padding: 10px 5px;
            font-size: 14px;
          }

          &.error {
            border-color: red;
          }
        }

        .errorMessage {
          margin: 0;
          color: red;
        }
      }
    }

    .buttonGroup {
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
