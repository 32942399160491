.button {
  position: relative;
  border: 0;
  padding: 8px 14px;
  background: var(--primary-color);
  color: white;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  min-width: 100px;

  img, svg {
    position: absolute;
    bottom: 22px;
    left: 22px;

    @media screen and (max-width: 559px) {
      bottom: 11px;
      left: 11px;
    }
  }

  @media screen and (max-width: 889px) {
    border: 0;
    padding: 8px 14px;
    background: var(--primary-color);
    color: rgb(255, 255, 255);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
  }

  &.secondary {
    background: white;
    color: black;
    border: 1px solid var(--border-color);

    &:hover {
      color: var(--primary-color);
    }

    @media screen and (max-width: 889px) {
      background: white;
      color: var(--primary-color);
      border: 1.5px solid var(--primary-color);;

      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &.big {
    padding: 20px 10px;
    font-size: 16px;
    border-radius: 10px;

    @media screen and (max-width: 559px) {
      padding: 10px 5px;
      font-size: 14px;
    }
  }

  &.fullWidth {
    padding: 18px 0;
    width: 90%;
  }

  &.light {
    border: 1.5px solid var(--primary-color);
    font-weight: lighter;
  }

  &.noBorder {
    border: 0;
  }

  &.loading {
    color: var(--primary-color);
  }

  &.loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }
}
