@font-face {
  font-family: 'CoinbaseSans';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/CoinbaseSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'CoinbaseSans';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/CoinbaseSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'CoinbaseDisplay';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/CoinbaseDisplay-Medium.woff2) format('woff2');
}

body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'CoinbaseSans',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,sans-serif;
  -webkit-font-smoothing: antialiased;
}

button {
  cursor: pointer;
}

@import "styles/animations";
@import "styles/variables";
@import "styles/toast";
