.toasts {
  z-index: 5000;
  position: fixed;
  top: 75px;
  right: 0;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 559px) {
    align-items: center;
  }

  .toast {
    border: 0;
    border-radius: 5px;
    background: #484848;
    color: #fff;
    padding: 12.5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 20px;

    animation: fadeIn 0.2s ease-in-out;

    .msg {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
    }

    .x-button {
      font-family: sans-serif;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}
